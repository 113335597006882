import { sumdate } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import { removeComma, reportReview } from '@/views/reports/useExcel'
import { ref } from '@vue/composition-api'

export default function useReportReview() {
  const searchtext = ref('')
  const XLSX = require('xlsx')
  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'id',
      width: 30,
    },
    { text: i18n.t('customer'), value: 'customer_fulname', width: 200 },
    { text: i18n.t('tel'), value: 'customer_tel', width: 100 },
    { text: i18n.t('age'), value: 'age', width: 50 },
    { text: i18n.t('gender'), value: 'customer_gender', width: 50 },
    { text: i18n.t('course_name'), value: 'course_name', width: 200 },
    {
      text: i18n.t('rating_all'),
      value: 'rating_all',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('rating_user'),
      value: 'rating_user',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('rating_doctor'),
      value: 'rating_doctor',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('rating_shop'),
      value: 'rating_shop',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('score_average'),
      value: 'score_average',
      width: 120,
      align: 'end',
    },
  ])
  const options = ref({})
  const loading = ref(true)
  const payload = ref({})
  const report = ref({})
  const exportLoading = ref(false)
  const dateStart = ref('')
  const dateEnd = ref('')

  const serachReport = async (start, end) => {
    loading.value = true
    if (start) {
      dateStart.value = start
    }
    if (end) {
      dateEnd.value = end
    }
    payload.value = {
      searchtext: searchtext.value,
      start: dateStart.value,
      end: dateEnd.value,
      lang: i18n.locale,
    }
    const { data } = await reportReview(payload.value)
    report.value = data || []
    dataTableList.value = data.list != null ? data.list : []
    dataTableList.value = dataTableList.value.map((item, i) => {
      item.order_create = sumdate(item.order_create, i18n.locale)

      return { ...item, number: i + 1 }
    })
    dataTableList.value.push({
      course_name: i18n.t('total'),
      rating_all: data.total_rating_all,
      rating_user: data.total_rating_user,
      rating_doctor: data.total_rating_doctor,
      rating_shop: data.total_rating_shop,
      score_average: data.total_score_average,
    })
    options.value.page = 1
    options.value.itemsPerPage = -1
    loading.value = false
  }

  const exportExcel = async () => {
    exportLoading.value = true

    let dataExport = dataTableList.value.map(item => {
      delete item.doctors
      delete item.consults

      return item
    })
    dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))
    const fileName = `${i18n.t('ReportSite')}.xlsx`

    // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
    /*  รายงานวิเคราะห์รายการตรวจ */

    const Heading = [
      [`${i18n.t('ReportOnline')}`],
      [
        '#',
        `${i18n.t('customer')}`,
        `${i18n.t('tel')}`,
        `${i18n.t('age')}`,
        `${i18n.t('gender')}`,
        `${i18n.t('course_name')}`,
        `${i18n.t('rating_all')}`,
        `${i18n.t('rating_user')}`,
        `${i18n.t('rating_doctor')}`,
        `${i18n.t('rating_shop')}`,
        `${i18n.t('score_average')}`,
      ],
    ]

    const ws = XLSX.utils.aoa_to_sheet(Heading)
    XLSX.utils.sheet_add_json(ws, dataExport, {
      header: [
        'number',
        'customer_fulname',
        'customer_tel',
        'age',
        'customer_gender',
        'course_name',
        'rating_all',
        'rating_user',
        'rating_doctor',
        'rating_shop',
        'score_average',
      ],
      skipHeader: true,
      origin: -1,
    })
    const wb = XLSX.utils.book_new()
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
    const wscols = [
      { wch: 6 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ]
    ws['!merges'] = merge
    ws['!cols'] = wscols
    XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ReportOnline')}`)

    /* พิมร์ files */
    XLSX.writeFile(wb, fileName)

    setTimeout(() => {
      exportLoading.value = false
    }, 1500)
  }

  return {
    searchtext,
    dataTableList,
    columns,
    options,
    loading,
    payload,
    report,
    exportLoading,
    dateStart,
    dateEnd,
    serachReport,
    exportExcel,
  }
}
